// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-scrapbook-js": () => import("./../src/pages/scrapbook.js" /* webpackChunkName: "component---src-pages-scrapbook-js" */),
  "component---src-template-article-js": () => import("./../src/template/article.js" /* webpackChunkName: "component---src-template-article-js" */),
  "component---src-template-blog-js": () => import("./../src/template/blog.js" /* webpackChunkName: "component---src-template-blog-js" */),
  "component---src-template-categoryposts-js": () => import("./../src/template/categoryposts.js" /* webpackChunkName: "component---src-template-categoryposts-js" */)
}

